"use client";
import { StyleSheet, View } from "react-native";

import { useAuth } from "@ctv/core";
import { Image, LocalizedLink, Token } from "@ctv/shared-core/src";

import { useHeaderHeight } from "./HeaderHeightContext";

export default function BlankHeader() {
  const auth = useAuth();
  const [_, setHeaderHeight] = useHeaderHeight();

  function handleLogout() {
    auth.logout();
  }

  return (
    <View
      nativeID="ctv-header"
      style={styles.wrapper}
      onLayout={(e) => setHeaderHeight(e.nativeEvent.layout.height)}
    >
      <View style={styles.container}>
        <LocalizedLink
          href="/"
          onClick={auth.isAuthenticated ? handleLogout : undefined}
        >
          <View testID="header.home-icon">
            <Image
              alt="Traveloka for Corporates"
              src={"/images/traveloka-for-corporates.png"}
              width={282}
            />
          </View>
        </LocalizedLink>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    boxShadow: Token.shadow.float,
    top: 0,
    zIndex: 500,
    // @ts-ignore
    position: "sticky",
    width: "100%",
    backgroundColor: Token.color.lightPrimary,
    borderTopWidth: 4,
    borderStyle: "solid",
    borderTopColor: Token.color.bluePrimary,
  },
  container: {
    width: "100%",
    minWidth: 1240,
    height: 56,
    paddingVertical: Token.spacing.xs,
    paddingHorizontal: Token.spacing.l,
    marginHorizontal: "auto",
    justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
});
