import { ReactNode } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";

import { useHoverable } from "../../../hooks/useHoverable";
import { isReactText } from "../../../utils";
import Checkbox from "../../Checkbox/Checkbox";
import Text from "../../Text/Text";
import Token from "../../Token/Token";

type DropdownItemProps = {
  testID?: string;
  label: string;
  subLabel?: ReactNode;
  checked: boolean;
  hasCheckbox: boolean;
  disabled?: boolean;
  onPress: () => void;
};

export default function InputCheckboxDropdownItem(props: DropdownItemProps) {
  const { testID, label, subLabel, checked, hasCheckbox, disabled, onPress } =
    props;

  const [isHovered, event] = useHoverable();
  const hoverStyle = {
    backgroundColor: Token.color.lightStain,
  };

  return (
    <TouchableOpacity
      {...event}
      testID={["check_box", testID].filter(Boolean).join("-")}
      onPress={onPress}
      style={[ItemStyle.container, isHovered && hoverStyle]}
      disabled={disabled}
    >
      {hasCheckbox && (
        <View style={ItemStyle.checkbox}>
          <Checkbox checked={checked} onChange={onPress} disabled={disabled} />
        </View>
      )}
      <Text ink={disabled ? "black-secondary" : "black-primary"}>{label} </Text>
      {isReactText(subLabel) ? (
        <Text
          variant="ui-tiny"
          ink={disabled ? "black-muted" : "black-secondary"}
        >
          {subLabel}
        </Text>
      ) : (
        subLabel
      )}
    </TouchableOpacity>
  );
}

const ItemStyle = StyleSheet.create({
  container: {
    flexDirection: "row",
    cursor: "pointer",
    padding: Token.spacing.xs,
    alignItems: "center",
    alignContent: "center",
  },
  checkbox: {
    marginRight: Token.spacing.s,
  },
});
