import { cloneElement, forwardRef, ReactElement } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  TouchableOpacityProps,
} from "react-native";

import Text, { TextProps } from "../Text";
import Token from "../Token";
import View from "../View";

type TouchableProps = Omit<TouchableOpacityProps, "onPress">;

export type TabProps = {
  fixed?: boolean;
  icon?: ReactElement;
  iconPosition?: "top" | "start" | "end";
  label: string;
  onPress?(value: string): void;
  selected?: boolean;
  value?: string;
  variant?: TextProps["variant"];
} & TouchableProps;

const Tab = forwardRef<TouchableOpacity, TabProps>((props, ref) => {
  const {
    accessibilityRole = 'menuitem',
    activeOpacity = 0.5,
    fixed,
    icon: iconProp,
    iconPosition = 'start',
    label,
    onPress,
    selected,
    style,
    value,
    variant = 'ui-baseline',
    testID,
    ...rest
  } = props;

  const iconColor = selected ? Token.color.bluePrimary : Token.color.darkSecondary;
  const textInk = selected ? 'interactive' : 'black-secondary';

  const icon =
    iconProp &&
    cloneElement(iconProp, {
      accentColor: iconColor,
      color: iconColor,
      height: 16,
      width: 16,
    });

  const rootStyle = [
    styles.root,
    fixed && styles.fixed,
    style,
    positionStyles[iconPosition],
  ];
  
  const iconStyle = [styles.icon, iconStyles[iconPosition]];

  function handlePress() {
    if (typeof onPress === 'function') onPress(value!);
  }

  return (
    <TouchableOpacity
      {...rest}
      accessibilityRole={accessibilityRole}
      activeOpacity={activeOpacity}
      onPress={handlePress}
      style={rootStyle}
      testID={['tab', testID].filter(Boolean).join('-')}
      ref={ref}
    >
      <View style={iconStyle}>{icon}</View>
      <Text ink={textInk} numberOfLines={1} style={styles.label} variant={variant}>
        {label}
      </Text>
    </TouchableOpacity>
  );
});

export default Tab;

const styles = StyleSheet.create({
  root: {
    paddingHorizontal: Token.spacing.xxs,
    alignItems: "center",
    justifyContent: "center",
  },
  fixed: {
    flex: 1,
  },
  icon: {
    flexShrink: 0,
  },
  label: {
    textAlign: "center",
  },
});

const positionStyles = StyleSheet.create({
  top: {},
  start: { flexDirection: "row" },
  end: { flexDirection: "row-reverse" },
});

const iconStyles = StyleSheet.create({
  top: {},
  start: { marginEnd: Token.spacing.xxs },
  end: { marginStart: Token.spacing.xxs },
});
