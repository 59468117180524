"use client";
import { useRef, useState } from "react";
import {
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  TextInputKeyPressEventData,
  View,
  ViewStyle,
} from "react-native";

import Fade from "../../../components/Fade/Fade";
import {
  Format,
  useLocalizedDateFormat,
} from "../../../core/locale/hooks/useLocalizedDateFormat";
import { appendTestId } from "../../../utils";
import Calendar from "../../Calendar/Calendar";
import Card from "../../Card/Card";
import Token from "../../Token/Token";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import InputField, { InputFieldProps } from "../InputField/InputField";

type Props = {
  value: Date;
  minDate?: Date;
  maxDate?: Date;
  dateFormat: Format;
  onDatePress?: (date: Date) => void;
  containerStyle?: StyleProp<ViewStyle>;
} & OmitTyped<InputFieldProps, "value" | "editable">;

export default function InputDatepicker(props: Props) {
  const {
    containerStyle,
    value,
    minDate,
    maxDate,
    dateFormat,
    onDatePress,
    testID,
    ...inputProps
  } = props;

  const { format } = useLocalizedDateFormat();
  const rootRef = useRef<View>(null);
  const [showCalendar, setShowCalendar] = useState(false);

  function handleFocus() {
    setShowCalendar(true);
  }

  function handleKeyPress(e: NativeSyntheticEvent<TextInputKeyPressEventData>) {
    switch (e.nativeEvent.key) {
      case "Tab":
        setShowCalendar(false);
        break;
    }
    return;
  }

  function handleDayPress(date: Date) {
    setShowCalendar(false);

    if (onDatePress) {
      onDatePress(date);
    }
  }

  useOnClickOutside(rootRef, () => {
    setShowCalendar(false);
  });

  return (
    <View ref={rootRef} style={containerStyle}>
      <InputField
        {...inputProps}
        forcedTestID={["date_picker", testID].filter(Boolean).join("-")}
        editable={false}
        onKeyPress={handleKeyPress}
        onFocus={handleFocus}
        value={format(value, dateFormat)}
      />
      <Fade visible={showCalendar} style={styles.dropdown}>
        <Card elevation="float">
          <Calendar
            maxDate={maxDate}
            minDate={minDate}
            onChange={handleDayPress}
            value={value}
          />
        </Card>
      </Fade>
    </View>
  );
}

const styles = StyleSheet.create({
  dropdown: {
    paddingTop: Token.spacing.xs,
    position: "absolute",
    left: 0,
    top: "100%",
    zIndex: 10,
  },
});
