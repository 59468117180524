import { HttpResponse } from "msw";
import { useCallback } from "react";

import {
  BookingFlowType,
  CreditCardType,
  PaymentMethod,
  ResponseStatus,
  useAuth,
  useClientApi,
} from "@ctv/core";
import config from "@ctv/core/config";
import { generateMswMock } from "@ctv/core/providers/msw/utils";

import { defaultHeaders } from "../api/useClientApi";
import {
  CORPORATE_SETTINGS_API,
  MFA_GATEWAY_CHECK_API,
  MFA_GATEWAY_REQUEST_OTP_API,
  MFA_GATEWAY_VERIFY_OTP_API,
} from "./api-urls";

type CorporateSettingsResponse = {
  bookingFlowType: BookingFlowType;
  creditCardTypes: CreditCardType[];
  paymentMethod?: PaymentMethod;
};
export function useFetchCorporateSettings() {
  return useClientApi<CorporateSettingsResponse>({
    domain: "management",
    method: "post",
    path: CORPORATE_SETTINGS_API,
  });
}

export type MfaGatewayCheckResponse = {
  email: string;
  authenticated: boolean;
  mfaGate: boolean;
  specialLoginGate: boolean;
  approvalGate: boolean;
  internalIpGate: boolean;
  flagged: boolean;
};
export function useMfaGatewayCheck() {
  const { getToken } = useAuth();

  return useCallback(async () => {
    const token = await getToken();

    if (!token) {
      return null;
    }

    return fetch(config.apiHost["mfa-sec-gateway"] + MFA_GATEWAY_CHECK_API, {
      method: "GET",
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
    }).then((res) => res.json() as Promise<MfaGatewayCheckResponse>);
  }, [getToken]);
}

type MfaGatewayReqOtpRequest = {
  email: string;
};
export type MfaGatewayReqOtpResponse = {
  gatewayMessage?: string;
  gatewayError?: string;
};
export function useMfaGatewayRequestOtp() {
  const { getToken } = useAuth();

  return useCallback(
    async (payload: MfaGatewayReqOtpRequest) => {
      const token = await getToken();

      if (!token) {
        return null;
      }

      return await fetch(
        config.apiHost["mfa-sec-gateway"] + MFA_GATEWAY_REQUEST_OTP_API,
        {
          method: "post",
          headers: {
            ...defaultHeaders,
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
          body: JSON.stringify(payload),
        }
      ).then((res) => res.json() as Promise<MfaGatewayReqOtpResponse>);
    },
    [getToken]
  );
}

type MfaGatewayVerifyOtpRequest = {
  verificationToken: string;
};
export type MfaGatewayVerifyOtpResponse = {
  gatewayMessage?: string;
  gatewayError?: string;
};
export function useMfaGatewayVerifyOtp() {
  const { getToken } = useAuth();

  return useCallback(
    async (payload: MfaGatewayVerifyOtpRequest) => {
      const token = await getToken();

      if (!token) {
        return null;
      }

      return fetch(
        config.apiHost["mfa-sec-gateway"] + MFA_GATEWAY_VERIFY_OTP_API,
        {
          method: "POST",
          headers: {
            ...defaultHeaders,
            Authorization: `Bearer ${token}`,
          },
          credentials: "include",
          body: JSON.stringify(payload),
        }
      ).then((res) => res.json() as Promise<MfaGatewayVerifyOtpResponse>);
    },
    [getToken]
  );
}

const mockUrl = `${config.apiHost.management}id-id${CORPORATE_SETTINGS_API}`;
export const coreAuthMockHandlers = [
  generateMswMock<CorporateSettingsResponse>(mockUrl, async () => {
    return HttpResponse.json({
      status: ResponseStatus.OK,
      data: {
        bookingFlowType: "TRAVEL_PERMIT",
        creditCardTypes: [
          CreditCardType.CORPORATE_CREDIT_CARD,
          CreditCardType.PERSONAL_CREDIT_CARD,
        ],
        paymentMethod: PaymentMethod.CREDIT_CARD,
      },
      trackingSpec: null,
    });
    // return HttpResponse.json({
    //     errorMessage: "Failed to retrieve data",
    //     userErrorMessage: "Failed to retrieve data",
    //     errorType: "BAD_REQUEST",
    //   } as ApiResponse<any>);
  }),
];
