import { Config } from "./types";

export const isProduction = process.env.NEXT_PUBLIC_CONFIG_ENV === "production";
export const isStaging = process.env.NEXT_PUBLIC_CONFIG_ENV === "staging";
export const isDemo = process.env.NEXT_PUBLIC_CONFIG_ENV === "demo";
export const isDevelopment = process.env.NODE_ENV === "development";
export const isDefault =
  !isProduction && !isStaging && !isDemo && !isDevelopment;

let config: Config;
if (isProduction) {
  config = require("./production").default;
} else if (isStaging) {
  config = require("./staging").default;
} else if (isDemo) {
  config = require("./demo").default;
} else if (isDevelopment) {
  config = require("./development").default;
} else {
  config = require("./default").default;
}

export default config;
