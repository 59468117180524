import React from "react";
import { StyleSheet, View } from "react-native";

import { Card, DotLoader, Text, Token } from "@ctv/shared-core/src";

export default function AuthLoading() {
  return (
    <View style={Style.page}>
      <Card style={Style.card}>
        <Text
          testID="auth_loading_title"
          variant="ui-large"
          style={Style.spacer}
        >
          {"Authenticating"}
        </Text>
        <Text variant="ui-small" style={Style.spacer}>
          {
            "You should be redirected to our home page after a few seconds. If redirection fails, please try refresh or reopen the page. If the problem persists, please contact your company PIC or dedicated Account Manager for further assistance."
          }
        </Text>
        <DotLoader />
      </Card>
    </View>
  );
}

const Style = StyleSheet.create({
  page: {
    // @ts-ignore
    height: "100vh",
    backgroundColor: Token.color.lightNeutral,
  },
  spacer: {
    marginBottom: Token.spacing.xs,
  },
  infoBox: {
    width: "100%",
  },
  card: {
    alignItems: "center",
    width: 500,
    marginHorizontal: "auto",
    marginTop: Token.spacing.xxxl,
    padding: Token.spacing.m,
  },
});
