import { Config } from "./types";

const config: Config = {
  appHost: "https://corporates.ctv.staging-traveloka.com",
  auth: {
    audience: "https://tvlk/ctv",
    domain: "tvlk-dev.auth0.com",
    clientId: "NqjzaMttt4KDsr5ehgly88cses607yJA",
    maxAge: 43200,
  },
  amplify: {
    Auth: {
      Cognito: {
        userPoolId: "ap-southeast-1_DKJ78n7hI",
        userPoolClientId: "361mlb5thatpiuapuocj43tj2f",
        signUpVerificationMethod: "code",
        loginWith: {
          oauth: {
            domain: "ctv-stg.auth.ap-southeast-1.amazoncognito.com",
            scopes: ["email", "profile", "openid"],
            redirectSignIn: ["https://corporates.ctv.staging-traveloka.com/"],
            redirectSignOut: ["https://corporates.ctv.staging-traveloka.com/"],
            responseType: "code",
          },
        },
      },
    },
  },
  apiHost: {
    search: "https://search-api.ctv.staging-traveloka.com/",
    booking: "https://booking-api.ctv.staging-traveloka.com/",
    refund: "https://refund-api.ctv.staging-traveloka.com/",
    management: "https://management-api.ctv.staging-traveloka.com/",
    content: "https://cntpapi.cnt.staging-traveloka.com/",
    data: "https://tracking.stg.tvlk-data.com/",
    "search-production": "https://search-api.ctv.traveloka.com/",
    "management-production": "https://management-api.ctv.traveloka.com/",
    "mfa-sec-gateway": "https://management-api.ctv.staging-traveloka.com/",
  },
  datadog: {
    applicationId: "5baafd98-432e-4b0c-ab28-544b08382ccb",
    clientToken: "pub7370a189bcfe0d00182d354507705a03",
    defaultPrivacyLevel: "mask",
    env: "stg",
    service: "corporate-travel-web-staging",
    // Specify a version number to identify the deployed version of your application in Datadog
    version: "1.0.0",
    sessionReplaySampleRate: 100,
    sessionSampleRate: 10,
    site: "datadoghq.com",
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
  },
  mock: {
    enabled: false,
  },
  securePayment: "https://payfrm.pay.staging-traveloka.com",
  secrets: {
    GENERATE_TNC_KEY: "f306e",
    REQUEST_TNC_SECRET_CODE: "yUR6Guw8oC",
  },
};

export default config;
