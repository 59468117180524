export function getCookie(name: string) {
  const cookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`));

  if (cookie) {
    return JSON.parse(cookie.split("=")[1]);
  }

  return null;
}

export function getPartialCookieName(name: string) {
  const cookies = document.cookie
    .split("; ")
    .filter((row) => row.startsWith(name));

  if (cookies.length) {
    return cookies.map((cookie) => {
      const [key, value] = cookie.split("=");
      return { key, value: JSON.parse(value) };
    });
  }

  return null;
}

export function setCookie(name: string, value: unknown, ageInSeconds: number) {
  document.cookie = `${name}=${JSON.stringify(
    value
  )}; max-age=${ageInSeconds}; path=/;`;
}

export function removeCookie(name: string) {
  document.cookie = `${name}=; max-age=0; path=/;`;
}
