"use client";
import { Amplify } from "aws-amplify";
import { remove, set } from "es-cookie";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { fetchAuthSession, signOut } from "@aws-amplify/auth";
import { AuthUserPoolAndIdentityPoolConfig, JWT } from "@aws-amplify/core";
import config from "@ctv/core/config";
import { useLocale, useLocalizedRouterServer } from "@ctv/shared-core/src";
import { enabledRUMEnvs } from "@ctv/shared/constants/monitoring";
import { datadogRum } from "@datadog/browser-rum";

import { isOffline } from "../dev/constants";

import {
  Auth,
  BookingFlowType,
  Country,
  PaymentMethod,
  Permission,
  User,
} from "./types";

const COGNITO_COOKIE_KEY = "awscognito.is.authenticated";
const AuthContext = createContext<Auth | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export function CognitoAuthProvider(props: Props) {
  const { children } = props;

  const locale = useLocale();
  const router = useLocalizedRouterServer();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User>();
  const isAuthenticated = !!user;

  async function applyUser(jwtData?: JWT["payload"]) {
    if (!jwtData) {
      return;
    }

    const {
      "https://tvlk/permissions": permissions,
      "https://tvlk/groups": groups,
      "https://tvlk/name": name,
      "https://tvlk/metadata": metadata,
      iat,
      email,
    } = jwtData as Record<string, string>;

    set(COGNITO_COOKIE_KEY, "true");

    const [
      corporateId,
      corporateCode,
      corporateName,
      corporateAlias,
      corporateStatus,
      rawGlobalTripRequestApproval,
      rawPrivateTripRequestApproval,
      paymentMethod,
      country,
    ] = JSON.parse(groups);
    const permissionMap = (JSON.parse(permissions) as Permission[]).reduce(
      (obj, p) => {
        obj[p] = true;

        return obj;
      },
      {} as Record<Permission, true>
    );

    // The backend will return any value based on the config, there are 4 possible values:
    // 1. Global false
    // 2. Global true
    // 3. Private false
    // 4. Private true
    //
    // There are only 3 expected state for Approval System:
    // 1. Global false & Private false
    // 2. Global true & Private false
    // 3. Global true & Private true
    //
    // THIS IS NOT ALLOWED: Global false & Private true
    const globalTripRequestApproval = rawGlobalTripRequestApproval === "true";
    const privateTripRequestApproval =
      globalTripRequestApproval && rawPrivateTripRequestApproval === "true";

    const { creditCardTypes, bookingFlowType } = JSON.parse(metadata);

    setUser({
      name,
      email,
      has(...permissions: Permission[]) {
        return permissions.some((p) => permissionMap[p]);
      },
      hasAll(...permissions: Permission[]) {
        return permissions.every((p) => permissionMap[p]);
      },
      corporateId,
      corporateCode,
      corporateName,
      corporateAlias,
      corporateStatus,
      globalTripRequestApproval,
      privateTripRequestApproval,
      loginTime: Number(iat) * 1000,
      paymentMethod,
      creditCardTypes,
      bookingFlowType,
      country,
    });

    try {
      if (config.datadog.env && enabledRUMEnvs.includes(config.datadog.env)) {
        datadogRum.setUser({
          name: corporateId || "-",
          email: corporateId || "-",
        });
      }
    } catch (error) {
      // No op.
    }
  }

  const updateUser = useCallback((user: Partial<User>) => {
    setUser((prev) =>
      prev
        ? {
            ...prev!,
            ...user,
          }
        : prev
    );
  }, []);

  function clearSession() {
    setUser(undefined);
    remove(COGNITO_COOKIE_KEY);

    try {
      if (config.datadog.env && enabledRUMEnvs.includes(config.datadog.env)) {
        datadogRum.clearUser();
      }
    } catch (error) {
      // No op.
    }
  }

  const login = useCallback(() => {
    router.push(`/login`);
  }, [router]);

  const logout = useCallback(() => {
    if (!isAuthenticated) {
      return;
    }

    signOut().then(() => clearSession());
  }, [isAuthenticated]);

  const getToken = useCallback(async () => {
    const token =
      (await fetchAuthSession()
        .then((res) => res?.tokens?.idToken?.toString())
        .catch(() => {
          logout();
        })) ?? undefined;
    return token;
  }, [logout]);

  useEffect(() => {
    const {
      redirectSignIn: rawRedirectSignIn,
      redirectSignOut: rawRedirectSignOut,
    } = config.amplify.Auth!.Cognito.loginWith!.oauth!;

    const isEnabled =
      process.env.NEXT_PUBLIC_CONFIG_ENV !== "production" &&
      process.env.NEXT_PUBLIC_CONFIG_ENV !== "staging";

    const redirectSignIn = [
      rawRedirectSignIn[0] + (isEnabled ? locale + "/" : ""),
    ];
    const redirectSignOut = [
      rawRedirectSignOut[0] + (isEnabled ? locale + "/" : ""),
    ];

    Amplify.configure(
      {
        ...config.amplify,
        Auth: {
          ...config.amplify.Auth,
          Cognito: {
            ...config.amplify.Auth!.Cognito,
            loginWith: {
              ...config.amplify.Auth!.Cognito.loginWith,
              oauth: {
                ...config.amplify.Auth!.Cognito.loginWith!.oauth!,
                redirectSignIn,
                redirectSignOut,
              },
            },
          } as AuthUserPoolAndIdentityPoolConfig["Cognito"],
        },
      },
      {
        ssr: true,
      }
    );

    fetchAuthSession()
      .then((res) => {
        if (res) {
          applyUser(res.tokens?.idToken?.payload);
        }
      })
      .catch(() => clearSession())
      .finally(() => setIsLoading(false));
  }, []);

  const value = isAuthenticated
    ? {
        getToken,
        applyUser,
        isAuthenticated,
        isLoading,
        login,
        logout,
        user,
        updateUser,
      }
    : {
        getToken,
        applyUser,
        isAuthenticated,
        isLoading,
        login,
        logout,
        user: undefined,
        updateUser,
      };

  if (isOffline) {
    value.user = devOfflineUser;
    value.isAuthenticated = true;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const value = useContext(AuthContext);

  if (value === undefined) {
    throw new Error("`AuthContext` could not find any provider");
  }

  return value;
}

const devOfflineUser = {
  name: "Development Offline User",
  email: "offline.user@development.com",
  has(..._permissions: Permission[]) {
    return true;
  },
  hasAll(..._permissions: Permission[]) {
    return true;
  },
  corporateId: "1",
  corporateCode: "OFFLINE CODE",
  corporateName: "Offline Company",
  corporateAlias: "Offline Alias",
  corporateStatus: "ACTIVE",
  globalTripRequestApproval: false,
  privateTripRequestApproval: false,
  loginTime: 0,
  paymentMethod: PaymentMethod.INVOICE,
  creditCardTypes: [],
  bookingFlowType: "NORMAL_FLOW" as BookingFlowType,
  country: Country.ID,
};
