"use client";
import { StyleSheet } from "react-native";

import { Button, Card, Modal, Text, Token } from "@ctv/shared-core/src";

type Props = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
};

export default function ResendCodeModal(props: Props) {
  const { isVisible, setIsVisible } = props;

  const cr = CRQuery.CorporateCognitoLogin;

  const onModalHide = () => {
    setIsVisible(false);
  };

  return (
    <Modal
      visible={isVisible}
      onExit={onModalHide}
      render={() => (
        <Card style={styles.container} flex align="center" justify="center">
          <Text variant="title-1">{cr.resendCodeTitle}</Text>
          <Text>{cr.resendCodeDescription}</Text>
          <Button
            style={styles.button}
            variant="secondary"
            text={cr.resendCodeButtonText}
            onPress={onModalHide}
          />
        </Card>
      )}
    ></Modal>
  );
}

const CRQuery = {
  CorporateCognitoLogin: {
    resendCodeTitle: "Your verification code has been re-sent",
    resendCodeDescription:
      "Your current code has been expired, please check your email again and enter the new code.",
    resendCodeButtonText: "Close",
  },
};

const styles = StyleSheet.create({
  container: {
    width: 460,
    maxWidth: 460,
    padding: Token.spacing.m,
  },
  button: {
    marginTop: Token.spacing.m,
    marginLeft: "auto",
  },
});
