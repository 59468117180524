import { FC, ReactElement, ReactNode } from "react";
import { FlatList } from "react-native";

import { appendTestId } from "../../../utils";
import Card from "../../Card";
import InputDropdownItem from "./InputDropdownItem";
import { InputDropdownItemValue } from "./types";

export type ItemComponentType = {
  testID?: string;
  isActive: boolean;
  onPress(): void;
} & InputDropdownItemValue;

export type InputDropdownListProps<T> = {
  testID?: string;
  items: T[];
  ItemComponent?: FC<ItemComponentType>;
  FilterEmptyComponent?: ReactElement;
  FooterComponent?: ReactNode;
  onPressItem: (item: T) => void;
  activeIndex: number;
  dropdownHeight?: number;
};

export default function InputDropdownList<T extends InputDropdownItemValue>(
  props: InputDropdownListProps<T>
) {
  const {
    testID,
    items,
    onPressItem,
    activeIndex,
    dropdownHeight = 350,
    ItemComponent,
    FilterEmptyComponent,
    FooterComponent,
  } = props;

  return (
    <Card elevation="float" overflow="hidden">
      <FlatList
        style={{ maxHeight: dropdownHeight }}
        initialNumToRender={10}
        ListEmptyComponent={FilterEmptyComponent}
        maxToRenderPerBatch={10}
        data={items}
        extraData={activeIndex}
        windowSize={5}
        keyExtractor={(item, index) => `${index}-${item.value}`}
        renderItem={({ item, index }) =>
          ItemComponent ? (
            <ItemComponent
              {...item}
              testID={[testID, "list", index].join("-")}
              isActive={activeIndex === index}
              onPress={() => onPressItem(item)}
            />
          ) : (
            <InputDropdownItem
              testID={[testID, "list", index].join("-")}
              label={item.label}
              subLabel={item.subLabel}
              isActive={activeIndex === index}
              onPress={() => onPressItem(item)}
            />
          )
        }
      />
      {FooterComponent}
    </Card>
  );
}
