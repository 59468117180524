import bowser from "bowser";
import { useCallback } from "react";
import { resolve } from "url";

import { useLocale } from "@ctv/shared-core/src";
import { getPartialCookieName } from "@ctv/shared-core/src/utils/cookie";

import { useAuth } from "../auth/CognitoAuthContext";
import config from "../config";
import ApiError from "./ApiError";
import { byPassApiToMapi, needCredentialDomains } from "./serverApi";
import {
  ApiOption,
  ApiResponse,
  ApiResult,
  ResponseError,
  ResponseStatus,
} from "./types";

export const defaultHeaders = {
  Accept: "application/json",
  "Content-Type": "application/json",
};

export default function useClientApi<Response = unknown, Request = unknown>(
  option: ApiOption
) {
  const { getToken, isAuthenticated } = useAuth();
  const locale = useLocale();

  const {
    domain,
    method,
    path,
    withAuth = true,
    localePrefix = true,
    options: paramOptions,
  } = option;
  const { apiHost } = config;

  return useCallback(
    async (
      payload: Request = {} as Request,
      requestInit?: RequestInit
    ): Promise<ApiResult<Response>> => {
      let authServiceToken: string | undefined;
      if (withAuth && isAuthenticated) {
        try {
          authServiceToken = await getToken();
          if (!authServiceToken) {
            throw new Error("No auth service token");
          }
        } catch (error) {
          return {
            success: false,
            error: new ApiError(ResponseError.NOT_AUTHORIZED, String(error)),
          };
        }
      }

      const { headers, ...restOptions } = { ...paramOptions, ...requestInit };
      const options: RequestInit = {
        headers: { ...defaultHeaders, ...headers },
        method,
        ...restOptions,
        credentials: needCredentialDomains.includes(domain)
          ? "include"
          : undefined,
      };

      if (method === "post") {
        // Quick fix to pass `trackingSpec` outside of `data`
        let trackingSpec: any = undefined;
        // @ts-ignore
        if ("trackingSpec" in payload) {
          const browser = bowser
            .getParser(window.navigator.userAgent)
            .getBrowser();

          // @ts-ignore
          trackingSpec = Object.assign({}, payload.trackingSpec, {
            webUrl: window.location.href,
            webReferrer: null,
            webBrowser: browser.name,
            webBrowserVersion: browser.version,
          });

          // @ts-ignore
          delete payload.trackingSpec;
        }

        options.body = JSON.stringify({
          clientInterface: "desktop",
          context: { authServiceToken },
          data: payload,
          fields: [],
          trackingSpec,
        });
      }

      const usedDomain = byPassApiToMapi(domain);
      const apiUrl = resolve(
        overwriteDomainApi(domain) ?? apiHost[usedDomain],
        ((localePrefix ? locale : "") + "/" + path).replace(/\/+/, "/")
      );
      const res = await fetch(apiUrl, options)
        .then<ApiResponse<Response>>((res) => res.json())
        .catch(() => null);

      /**
       * Type guard for 404, play framework html error, and any other error resulting
       * in non-json return type
       */
      if (typeof res !== "object" || res === null) {
        return {
          success: false,
          error: new ApiError(
            ResponseError.API_CALL_NOT_RETURNING_JSON,
            JSON.stringify({
              api_response: res,
              api_path_url: apiUrl,
            })
          ),
        };
      }

      // Public API
      if ("errorType" in res) {
        const { errorType } = res;

        let errorMessage = res.userErrorMessage;
        if (errorType === ResponseError.NOT_AUTHORIZED) {
          errorMessage = res.errorMessage;
        }

        return {
          success: false,
          error: new ApiError(errorType, errorMessage),
        };
      }

      // CTV API
      if ("status" in res && res.status !== ResponseStatus.OK) {
        const { status, errorMessage } = res;

        return {
          success: false,
          error: new ApiError(status, errorMessage),
        };
      }

      return {
        success: true,
        // @ts-expect-error the `?? res` is to cover for the non-standard CTV response like MFA OTP
        data: res.data ?? res,
        // Quick inject trackingSpec
        trackingSpec: res.trackingSpec,
      };
    },
    [
      domain,
      method,
      path,
      isAuthenticated,
      locale,
      getToken,
      apiHost,
      localePrefix,
      withAuth,
      paramOptions,
    ]
  );
}

function overwriteDomainApi(domain: string) {
  const overwriteApiDomain = getPartialCookieName("apiHost");
  const overwritenPort = overwriteApiDomain?.find(({ key }) =>
    key.endsWith(domain)
  )?.value;

  if (overwritenPort) {
    return "http://localhost:" + overwritenPort;
  }

  return null;
}
